"use client";

import { WelcomeHeader } from "#/app/[site]/site/[lang]/app/(onboarding)/onboarding-header";
import UnProtectedPage from "#/components/layout/unprotected-page";
import { useOnLogin } from "#/hooks/use-on-login";
import { useTStream } from "#/hooks/use-t-stream";
import { mutations } from "#/lib/atoms/mutations";
import { Button } from "#/ui/button";

import { Separator } from "#/ui/separator";
import { Input } from "#/ui/standard-input";
import { useAtom } from "jotai";
import { Link } from "next-view-transitions";
import { useSearchParams } from "next/navigation";
import { Suspense, useCallback } from "react";
import { useForm } from "react-hook-form";

export function LoginPageClient() {
  return (
    <UnProtectedPage>
      <Suspense>
        <Component />
      </Suspense>
    </UnProtectedPage>
  );
}

const Component = () => {
  const searchParams = useSearchParams();
  const email = searchParams?.get("email");
  const { onLogin } = useOnLogin();
  const { register, handleSubmit } = useForm({
    defaultValues: {
      email: email || "",
      password: "",
    },
  });

  const [{ isPending: isMutating, mutateAsync: trigger }] = useAtom(
    mutations.authLogin,
  );

  const onContinue = useCallback(
    async (data: any) => {
      try {
        const res = await trigger(data);
        await onLogin(res.access_token, res.refresh_token);
      } catch (error) {
        console.error(error);
      }
    },
    [trigger, onLogin],
  );

  const { t } = useTStream("common");

  return (
    <form
      onSubmit={handleSubmit(onContinue)}
      className="w-full mt-[5%] overflow-none space-y-8 items-center flex flex-col justify-center"
    >
      <div className="md:max-w-[470px] px-10 md:px-0 space-y-10 w-full items-center justify-center">
        <WelcomeHeader
          title={t("Welcome Back")}
          description={t("Enter email to continue")}
        />
        <Input
          title={t("Email")}
          placeholder={t("Email")}
          rootStyles="mt-10 mb-4 w-full"
          register={{ ...register("email") }}
        />
        <div className="mt-7 mb-4 w-full">
          <Input
            title={t("Password")}
            placeholder={t("Password")}
            secure
            rootStyles="w-full"
            register={{ ...register("password") }}
          />
          <div className="flex mt-1 w-full justify-end">
            <Link
              className="text-muted underline text-sm font-400"
              href="/forgot"
            >
              {t("Forgot Password")}
            </Link>
          </div>
        </div>
        <Button loading={isMutating} className="w-full">
          {t("Continue")}
        </Button>
      </div>
      <Separator className="my-5" />
      <div className="flex items-center space-x-2">
        <p className="text-muted font-400 text-sm">
          {t("Don't have an account yet?")}
        </p>
        <Link className="text-primary text-sm font-500" href="/register">
          {t("Sign up")}
        </Link>
      </div>
    </form>
  );
};
