import { atoms, useResetAtoms } from "#/lib/atoms/atoms";
import cookies from "#/lib/cookies";
import { queryClient } from "#/lib/query";
import { fetchRequest } from "#/lib/stream-api";
import { useSetAtom } from "jotai";
import { useRouter } from "next/navigation";
import { useCallback } from "react";

export const useOnLogin = () => {
  const router = useRouter();
  const resetAtoms = useResetAtoms();
  const setMid = useSetAtom(atoms.mid);
  const setAccessToken = useSetAtom(atoms.accessToken);
  const setOrgId = useSetAtom(atoms.orgId);
  const setCurrentMenu = useSetAtom(atoms.currentMenuId);
  const setCurrentLocation = useSetAtom(atoms.currentLocationId);
  /** Could maybe justify it's own hook */
  const setCurrentOrgMerchant = useCallback(
    (options: { org_id: string; merchant_id: string }) => {
      const { org_id, merchant_id } = options;
      setCurrentMenu(null);
      setCurrentLocation(null);
      cookies.set(cookies.options.org_id, org_id);
      setOrgId(org_id);
      cookies.set(cookies.options.merchant_id, merchant_id);
      setMid(merchant_id);
    },
    [setMid, setOrgId],
  );

  const onLogin = useCallback(
    async (access_token, refresh_token, skip_redirect?: boolean) => {
      resetAtoms();
      cookies.set(cookies.options.access_token, access_token);
      setAccessToken(access_token);
      cookies.set(cookies.options.refresh_token, refresh_token);
      const orgs = await queryClient.fetchQuery({
        queryKey: ["orgs", access_token],
        staleTime: 0,
        queryFn: () => fetchRequest("/org"),
      });
      const org_id = orgs.find((o) => o?.is_default)?._id || orgs[0]?._id;
      const merchant_id = orgs.find((o) => o?._id === org_id)?.merchants[0]
        ?._id;
      setCurrentOrgMerchant({
        org_id,
        merchant_id,
      });
      if (!skip_redirect) {
        await router.push("/menu");
        return {};
      } else {
        return {
          merchant_id: orgs[0]?.merchants[0]?._id,
        };
      }
    },
    [router, setAccessToken, setCurrentOrgMerchant, resetAtoms],
  );

  return {
    onLogin,
    setCurrentOrgMerchant,
  };
};
